import { default as _91templateId_936dYi7Ejlg5Meta } from "/vercel/path0/apps/template-admin/pages/edit/[templateId].vue?macro=true";
import { default as indexXapLqUsFwTMeta } from "/vercel/path0/apps/template-admin/pages/index.vue?macro=true";
import { default as _91contractId_93T5b6iu5n5XMeta } from "/vercel/path0/apps/template-admin/pages/preview/[templateId]/[contractId].vue?macro=true";
export default [
  {
    name: "edit-templateId",
    path: "/edit/:templateId()",
    meta: _91templateId_936dYi7Ejlg5Meta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/edit/[templateId].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXapLqUsFwTMeta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/index.vue")
  },
  {
    name: "preview-templateId-contractId",
    path: "/preview/:templateId()/:contractId()",
    component: () => import("/vercel/path0/apps/template-admin/pages/preview/[templateId]/[contractId].vue")
  }
]