import revive_payload_client_EIeauEleWe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EqoLDnr2Ez from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IK0Jr3ZfQ3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5paLenP4gp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_871ftf4mPK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xFc7VAjpOO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KbemWb6bfB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/template-admin/.nuxt/components.plugin.mjs";
import prefetch_client_Ns64g8JMKk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._2lpp4aenwxvctugikhgm2ziaxi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_prod_client_GI1ZxVc8v4 from "/vercel/path0/node_modules/.pnpm/nuxt-monaco-editor@1.3.1_magicast@0.3.5_monaco-editor@0.52.0_rollup@4.22.5_vite@5.4.8_@types+_junuqotnwwv6lnu2t74l532azi/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.js";
import switch_locale_path_ssr_YxRcO84sMh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_5MfVKlP8dM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
export default [
  revive_payload_client_EIeauEleWe,
  unhead_EqoLDnr2Ez,
  router_IK0Jr3ZfQ3,
  payload_client_5paLenP4gp,
  navigation_repaint_client_871ftf4mPK,
  check_outdated_build_client_xFc7VAjpOO,
  chunk_reload_client_KbemWb6bfB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ns64g8JMKk,
  plugin_prod_client_GI1ZxVc8v4,
  switch_locale_path_ssr_YxRcO84sMh,
  i18n_5MfVKlP8dM,
  plugin_ywJDomcNte
]